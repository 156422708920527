import React from 'react';
import { Page } from '../components';

import nft1 from '../assets/1.jpg';
import nft2 from '../assets/2.jpg';
import nft3 from '../assets/3.jpg';
import nft4 from '../assets/4.jpg';

interface ImageProps {
  src: string;
  spacing: number;
}

const Image = ({ src, spacing }: ImageProps) => {
  return (
    <div className="w-6/12">
      <img
        src={src}
        alt=""
        loading="lazy"
        style={{ width: `calc(100% - (${spacing}px * 2)`, margin: `${spacing}px` }}
      />
    </div>
  );
};

export function HomePage() {
  return (
    <Page>
      <>
        <div className="py-6 px-2 h-full flex flex-col justify-between sm:py-24 lg:hidden">
          <div className="py-1 text-center">
            <h1 className="text-6xl font-bloody text-pink italic sm:text-6xl md:text-7xl">Vampire</h1>
            <h1 className="text-6xl font-bloody text-pink italic sm:text-6xl md:text-7xl">Valentines</h1>
          </div>

          <div className="flex flex-wrap px-2 sm:px-16 sm:py-12">
            <Image src={nft1} spacing={10} />
            <Image src={nft2} spacing={10} />
            <Image src={nft3} spacing={10} />
            <Image src={nft4} spacing={10} />
          </div>

          <div className="py-1 text-center">
            <button type="button" className="text-5xl font-bloody text-pink italic sm:text-5xl md:text-6xl">
              Mint
            </button>
          </div>
          <div className="text-center">
            <p className="py-4 text-2xl font-bloody text-pink italic sm:text-2xl md:text-3xl">0 / 214</p>
          </div>
        </div>
        {/* non-mobile */}
        <div className="hidden py-6 px-2 h-full lg:flex flex-col">
          <div className="hidden lg:grid lg:grid-cols-2">
            <div className="col-span-1">
              <div className="flex flex-wrap p-4">
                <Image src={nft1} spacing={10} />
                <Image src={nft2} spacing={10} />
                <Image src={nft3} spacing={10} />
                <Image src={nft4} spacing={10} />
              </div>
            </div>
            <div className="col-span-1 flex flex-col justify-center items-center">
              <h1 className="text-7xl font-bloody text-pink italic xl:text-8xl 2xl:text-9xl">Vampire</h1>
              <h1 className="text-7xl font-bloody text-pink italic xl:text-8xl 2xl:text-9xl">Valentines</h1>
            </div>
          </div>

          <div className="text-center pt-24">
            <button type="button" className="text-6xl font-bloody text-pink italic xl:text-7xl 2xl:text-8xl">
              Mint
            </button>
          </div>
          <div className="text-center">
            <p className="py-4 text-4xl font-bloody text-pink italic xl:text-5xl xl:py-8 2xl:text-6xl 2xl:py-12">
              0 / 214
            </p>
          </div>
        </div>
      </>
    </Page>
  );
}
