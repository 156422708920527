import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import AuthenticatedRoute from './AuthenticatedRoute';
import ConnectedEthRoute from './ConnectedETHRoute';

import { routes } from './routes';

import { HomePage } from '../pages';

export default function AppRouter(): JSX.Element {
  return (
    <Router>
      <Switch>
        <Route path="/" component={HomePage} />
      </Switch>
    </Router>
  );
}
